import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Wir sorgen dafür, dass sich Ihre Daten verstehen!`}</p>
    <p>{`Heterogen gewachsene Unternehmen besitzen oft IT-Infrastrukturen, die aus einzelnen Anwendungen und Applikationen gewachsen sind. Wir automatisieren diese Vernetzung.`}</p>
    <h2>{`Wir bieten:`}</h2>
    <ul>
      <li parentName="ul">{`Automatisierung der Vernetzung verschiedener Anwendungen`}</li>
      <li parentName="ul">{`Koordination heterogener IT-Systeme`}</li>
      <li parentName="ul">{`Datenaustausch und -abgleich automatisieren`}</li>
      <li parentName="ul">{`Entwurf und Implementierung eines unternehmensweiten einheitlichen Content Management Systems (CMS)`}</li>
      <li parentName="ul">{`Kompatibilität mit vorhandenen Systemkomponenten`}</li>
      <li parentName="ul">{`Verbindung über verschiedene Schnittstellen-Typen, zum Beispiel Webservices, Datei- oder Hardwareschnittstellen, APIs, Schnittstellentabellen in Datenbanken und mehr`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      